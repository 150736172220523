export const environment = {
  production: true,
  apiUrl: 'https://production.starsmap.internal.express42.io',
  apiDelayMs: 10,

  ui: {
    logo_url: '/assets/icons/org-logo-express42.svg',
  },

  keycloak: {
    url: 'https://sso.e42.work',
    realm: 'e42',
    client_id: 'starsmap-webui',
    role_user: 'starsmap-user',
    role_admin: 'starsmap-admin',
  },

  sentry: {
    is_active: true,
    dsn: 'https://579670d40e174ae09d7368488527e7e9@sentry.starsmap.internal.express42.io/3',
    tracingOrigins: ['https://production.starsmap.internal.express42.io/'],
    environment: 'production',
  },
};
